import React from 'react';
import './GrapeAgComingSoon.css'; // Import the CSS file for styling
import grapeAgLogo from '../../../assets/images/ventures-grape-ag-logo-horizontal.png'; // Adjust the import path

const GrapeAgComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <img src={grapeAgLogo} alt="Grape Ag Logo" className="coming-soon-logo" />
            <h1 className="coming-soon-text">Coming soon...</h1>
        </div>
    );
};

export default GrapeAgComingSoon;
